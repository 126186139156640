












import Vue from "vue";
import Navigation from "@/components/Navigation.vue";
import Footer from "./components/Footer.vue";

export default Vue.extend({
  components: { Navigation, Footer },
  name: "App",
});
