import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VFooter,{staticClass:"footer px-8",attrs:{"color":"#015869"}},[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VRow,{attrs:{"align":"center","justify":"center","justify-md":"start"}},[_c(VCard,{attrs:{"color":"transparent","bottom":"","flat":"","tile":""}},[_c(VCardText,{staticClass:"footer__copyright"},[_vm._v(" © "+_vm._s(new Date().getFullYear())+" — Spencer Krips | "),_c('a',{staticClass:"footer__copyright--link",attrs:{"href":"https://www.carmengoetz.ca","target":"_blank"}},[_vm._v(" Carmen Goetz ")])])],1)],1)],1),_c(VSpacer),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VRow,{attrs:{"justify":"center","justify-sm":"end"}},[_c(VCard,{attrs:{"flat":"","tile":"","color":"transparent"}},_vm._l((_vm.Footer.socials),function(item){return _c(VBtn,{key:item.icon,staticClass:"footer__social-image mx-2 pa-5",attrs:{"icon":"","elevation":"0","href":item.link,"target":"_blank","rel":"noreferrer","width":"28","height":"28"}},[(item.icon)?_c(VIcon,{attrs:{"size":"32","color":"white"}},[_vm._v(" "+_vm._s(item.icon)+" ")]):(item.image)?_c(VImg,{attrs:{"src":item.image,"alt":item.image,"width":"28","height":"28"}}):_vm._e()],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }