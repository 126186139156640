type Category = {
  id: number;
  title: string;
};

type Product = {
  id: number;
  categories: number[];
  name: string;
  format: string;
  currency: string;
  price: number;
  image: NodeRequire;
};

type Shop = {
  categories: Category[];
  products: Product[];
};

export const Shop: Shop = {
  categories: [
    {
      id: 391403045112,
      title: "All Music",
    },
    {
      id: 391509016824,
      title: "Instrumental",
    },
    {
      id: 391403110648,
      title: "Choir",
    },
    {
      id: 391403176184,
      title: "Recordings",
    },
    {
      id: 391403241720,
      title: "Apparel",
    },
  ],
  products: [
    {
      id: 7584649216248,
      categories: [391403045112, 391403176184],
      name: "behold, the sonic universe",
      format: "CD",
      currency: "CA",
      price: 15,
      image: require("../assets/products/behold-the-sonic-universe.png"),
    },
    {
      id: 7584640827640,
      categories: [391403045112, 391509016824],
      name: "behold, the sonic universe",
      format: "Marimba & Vibraphone",
      currency: "CA",
      price: 45,
      image: require("../assets/products/behold-the-sonic-universe.png"),
    },
    {
      id: 7584636502264,
      categories: [391403045112, 391509016824],
      name: "Jenny",
      format: "Adaptable / Brass / Concert Band",
      currency: "CA",
      price: 75,
      image: require("../assets/products/jenny.png"),
    },
    {
      id: 7589284446456,
      categories: [391403045112, 391509016824],
      name: "Perseus",
      format: "Brass Band",
      currency: "USD",
      price: 80,
      image: require("../assets/products/perseus.png"),
    },
    {
      id: 7584641220856,
      categories: [391403045112, 391509016824],
      name: "Pseudopop in Teal",
      format: "Trombone Quartet",
      currency: "CA",
      price: 20,
      image: require("../assets/products/pseudopop-in-teal.png"),
    },
    {
      id: 7584642760952,
      categories: [391403045112, 391403110648],
      name: "See How Low the Moon Hangs",
      format: "SATB Divisi",
      currency: "CA",
      price: 3,
      image: require("../assets/products/see-how-low-the-moon-hangs.png"),
    },
    {
      id: 7584644366584,
      categories: [391403045112, 391509016824],
      name: "to be whole",
      format: "Brass / Concert Band",
      currency: "CA",
      price: 100,
      image: require("../assets/products/to-be-whole.png"),
    },
    {
      id: 7584646660344,
      categories: [391403045112, 391509016824],
      name: "Ward Lock",
      format: "Jazz Ensemble",
      currency: "CA",
      price: 70,
      image: require("../assets/products/ward-lock.png"),
    },
    {
      id: 7584648331512,
      categories: [391403045112, 391403110648],
      name: "what counts as silence",
      format: "SATB Divisi",
      currency: "CA",
      price: 3,
      image: require("../assets/products/what-counts-as-silence.png"),
    },
    // Apparel - not on website for now
    // {
    //   id: 7584655278328,
    //   categories: [391403241720],
    //   name: "Jenny",
    //   format: "Black Border - Unisex T-Shirt",
    //   currency: "CA",
    //   price: 35,
    //   image: require("../assets/products/jenny-unisex-black.png"),
    // },
    // {
    //   id: 7584651051256,
    //   categories: [391403241720],
    //   name: "Jenny",
    //   format: "Black Border - Women's T-Shirt",
    //   currency: "CA",
    //   price: 35,
    //   image: require("../assets/products/jenny-women-black.png"),
    // },
    // {
    //   id: 7584654098680,
    //   categories: [391403241720],
    //   name: "Jenny",
    //   format: "White Border - Unisex T-Shirt",
    //   currency: "CA",
    //   price: 35,
    //   image: require("../assets/products/jenny-unisex-white.png"),
    // },
    // {
    //   id: 7584650756344,
    //   categories: [391403241720],
    //   name: "Jenny",
    //   format: "White Border - Women's T-Shirt",
    //   currency: "CA",
    //   price: 35,
    //   image: require("../assets/products/jenny-women-white.png"),
    // },
    // {
    //   id: 7584660881656,
    //   categories: [391403241720],
    //   name: "Kibo",
    //   format: "Black Border - Unisex T-Shirt",
    //   currency: "CA",
    //   price: 35,
    //   image: require("../assets/products/kibo-unisex-black.png"),
    // },
    // {
    //   id: 7584651542776,
    //   categories: [391403241720],
    //   name: "Kibo",
    //   format: "Black Border - Women's T-Shirt",
    //   currency: "CA",
    //   price: 35,
    //   image: require("../assets/products/kibo-women-black.png"),
    // },
    // {
    //   index: 15,
    //   id: 7584657637624,
    //   categories: [391403241720],
    //   name: "Kibo",
    //   format: "White Border - Unisex T-Shirt",
    //   currency: "CA",
    //   price: 35,
    //   image: require("../assets/products/kibo-unisex-white.png"),
    // },
    // {
    //   id: 7584649904376,
    //   categories: [391403241720],
    //   name: "Kibo",
    //   format: "White Border - Women's T-Shirt",
    //   currency: "CA",
    //   price: 35,
    //   image: require("../assets/products/kibo-women-white.png"),
    // },
    // {
    //   id: 7584653574392,
    //   categories: [391403241720],
    //   name: "of loss",
    //   format: "Black Border - Unisex T-Shirt",
    //   currency: "CA",
    //   price: 35,
    //   image: require("../assets/products/of-loss-unisex-black.png"),
    // },
    // {
    //   id: 7584650526968,
    //   categories: [391403241720],
    //   name: "of loss",
    //   format: "Black Border - Women's T-Shirt",
    //   currency: "CA",
    //   price: 35,
    //   image: require("../assets/products/of-loss-women-black.png"),
    // },
    // {
    //   id: 7584653279480,
    //   categories: [391403241720],
    //   name: "of loss",
    //   format: "White Border - Unisex T-Shirt",
    //   currency: "CA",
    //   price: 35,
    //   image: require("../assets/products/of-loss-unisex-white.png"),
    // },
    // {
    //   id: 7584650166520,
    //   categories: [391403241720],
    //   name: "of loss",
    //   format: "White Border - Women's T-Shirt",
    //   currency: "CA",
    //   price: 35,
    //   image: require("../assets/products/of-loss-women-white.png"),
    // },
    // {
    //   id: 7584654000376,
    //   categories: [391403241720],
    //   name: "Perseus",
    //   format: "Black Border - Unisex T-Shirt",
    //   currency: "CA",
    //   price: 35,
    //   image: require("../assets/products/perseus-unisex-black.png"),
    // },
    // {
    //   id: 7584650592504,
    //   categories: [391403241720],
    //   name: "Perseus",
    //   format: "Black Border - Women's T-Shirt",
    //   currency: "CA",
    //   price: 35,
    //   image: require("../assets/products/perseus-women-black.png"),
    // },
    // {
    //   id: 7584653869304,
    //   categories: [391403241720],
    //   name: "Perseus",
    //   format: "White Border - Unisex T-Shirt",
    //   currency: "CA",
    //   price: 35,
    //   image: require("../assets/products/perseus-unisex-white.png"),
    // },
    // {
    //   id: 7584650428664,
    //   categories: [391403241720],
    //   name: "Perseus",
    //   format: "White Border - Women's T-Shirt",
    //   currency: "CA",
    //   price: 35,
    //   image: require("../assets/products/perseus-women-white.png"),
    // },
    // {
    //   id: 7584663732472,
    //   categories: [391403241720],
    //   name: "Pseudopop in Teal",
    //   format: "Black Border - Unisex T-Shirt",
    //   currency: "CA",
    //   price: 35,
    //   image: require("../assets/products/pseudopop-in-teal-unisex-black.png"),
    // },
    // {
    //   id: 7584651870456,
    //   categories: [391403241720],
    //   name: "Pseudopop in Teal",
    //   format: "Black Border - Women's T-Shirt",
    //   currency: "CA",
    //   price: 35,
    //   image: require("../assets/products/pseudopop-in-teal-women-black.png"),
    // },
    // {
    //   id: 7584662454520,
    //   categories: [391403241720],
    //   name: "Pseudopop in Teal",
    //   format: "White Border - Unisex T-Shirt",
    //   currency: "CA",
    //   price: 35,
    //   image: require("../assets/products/pseudopop-in-teal-unisex-white.png"),
    // },
    // {
    //   id: 7584651804920,
    //   categories: [391403241720],
    //   name: "Pseudopop in Teal",
    //   format: "White Border - Women's T-Shirt",
    //   currency: "CA",
    //   price: 35,
    //   image: require("../assets/products/pseudopop-in-teal-women-white.png"),
    // },
    // {
    //   id: 7584669139192,
    //   categories: [391403241720],
    //   name: "See How Low the Moon Hangs",
    //   format: "Black Border - Unisex T-Shirt",
    //   currency: "CA",
    //   price: 35,
    //   image: require("../assets/products/see-how-low-the-moon-hangs-unisex-black.png"),
    // },
    // {
    //   id: 7584652165368,
    //   categories: [391403241720],
    //   name: "See How Low the Moon Hangs",
    //   format: "Black Border - Women's T-Shirt",
    //   currency: "CA",
    //   price: 35,
    //   image: require("../assets/products/see-how-low-the-moon-hangs-women-black.png"),
    // },
    // {
    //   id: 7584666222840,
    //   categories: [391403241720],
    //   name: "See How Low the Moon Hangs",
    //   format: "White Border - Unisex T-Shirt",
    //   currency: "CA",
    //   price: 35,
    //   image: require("../assets/products/see-how-low-the-moon-hangs-unisex-white.png"),
    // },
    // {
    //   id: 7584652034296,
    //   categories: [391403241720],
    //   name: "See How Low the Moon Hangs",
    //   format: "White Border - Women's T-Shirt",
    //   currency: "CA",
    //   price: 35,
    //   image: require("../assets/products/see-how-low-the-moon-hangs-women-white.png"),
    // },
    // {
    //   id: 7584653050104,
    //   categories: [391403241720],
    //   name: "Ward Lock",
    //   format: "Black Border - Unisex T-Shirt",
    //   currency: "CA",
    //   price: 35,
    //   image: require("../assets/products/ward-lock-unisex-black.png"),
    // },
    // {
    //   id: 7584649740536,
    //   categories: [391403241720],
    //   name: "Ward Lock",
    //   format: "Black Border - Women's T-Shirt",
    //   currency: "CA",
    //   price: 35,
    //   image: require("../assets/products/ward-lock-women-black.png"),
    // },
    // {
    //   id: 7584652787960,
    //   categories: [391403241720],
    //   name: "Ward Lock",
    //   format: "White Border - Unisex T-Shirt",
    //   currency: "CA",
    //   price: 35,
    //   image: require("../assets/products/ward-lock-unisex-white.png"),
    // },
    // {
    //   id: 7584650002680,
    //   categories: [391403241720],
    //   name: "Ward Lock",
    //   format: "White Border - Women's T-Shirt",
    //   currency: "CA",
    //   price: 35,
    //   image: require("../assets/products/ward-lock-women-white.png"),
    // },
    // {
    //   id: 7584673988856,
    //   categories: [391403241720],
    //   name: "what counts as silence",
    //   format: "Black Border - Unisex T-Shirt",
    //   currency: "CA",
    //   price: 35,
    //   image: require("../assets/products/what-counts-as-silence-unisex-black.png"),
    // },
    // {
    //   id: 7584652361976,
    //   categories: [391403241720],
    //   name: "what counts as silence",
    //   format: "Black Border - Women's T-Shirt",
    //   currency: "CA",
    //   price: 35,
    //   image: require("../assets/products/what-counts-as-silence-women-black.png"),
    // },
    // {
    //   id: 7584672022776,
    //   categories: [391403241720],
    //   name: "what counts as silence",
    //   format: "White Border - Unisex T-Shirt",
    //   currency: "CA",
    //   price: 35,
    //   image: require("../assets/products/what-counts-as-silence-unisex-white.png"),
    // },
    // {
    //   id: 7584652493048,
    //   categories: [391403241720],
    //   name: "what counts as silence",
    //   format: "White Border - Women's T-Shirt",
    //   currency: "CA",
    //   price: 35,
    //   image: require("../assets/products/what-counts-as-silence-women-white.png"),
    // },
  ],
};
