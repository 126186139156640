






















































import Vue from "vue";
import logo from "@/assets/logo.png";

export default Vue.extend({
  name: "Navigation",

  data: () => ({
    pages: [
      { path: "/", name: "Home", exact: true },
      { path: "/bio", name: "Bio", exact: false },
      { path: "/events", name: "Events", exact: false },
      { path: "/press", name: "Press", exact: false },
      { path: "/shop", name: "Shop", exact: false },
      { path: "/contact", name: "Contact", exact: false },
    ],
    logo,
  }),
});
