





















































import Vue from "vue";
import { Footer } from "@/constants";

export default Vue.extend({
  name: "Footer",
  data: () => ({
    Footer,
  }),
});
