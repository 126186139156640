import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{staticClass:"nav px-0 px-lg-16",attrs:{"absolute":"","color":"#8a4d58","flat":"","height":"78"}},[_c('router-link',{staticClass:"nav__logo",attrs:{"data-text":"Spencer Krips Music","to":{
      name: 'Home',
    }}},[_c(VImg,{staticClass:"nav__logo",attrs:{"src":_vm.logo,"alt":"Spencer Krips Music"}})],1),_c(VSpacer),_c('div',{staticClass:"nav__links"},_vm._l((_vm.pages),function(page){return _c('router-link',{key:page.name,staticClass:"nav__link px-4 py-7 d-none d-md-inline",attrs:{"to":{
        name: page.name,
      }}},[_vm._v(" "+_vm._s(page.name)+" ")])}),1),_c(VMenu,{staticClass:"nav__mobile d-inline d-md-none mt-16",attrs:{"tile":"","max-width":"960"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c(VAppBarNavIcon,_vm._g(_vm._b({staticClass:"hidden-lg-and-up",attrs:{"color":"white","large":""}},'v-app-bar-nav-icon',attrs,false),on))]}}])},[_c(VCard,{staticClass:"nav__links--mobile d-inline d-md-none mx-0"},[_c(VList,{staticClass:"py-0",attrs:{"tile":"","color":"#8a4d58"}},_vm._l((_vm.pages),function(page){return _c(VListItem,{key:page.name,staticClass:"px-0"},[_c('router-link',{staticClass:"nav__link nav__link--mobile py-4 text-center px-16",attrs:{"data-text":page.name,"to":{
              name: page.name,
            }}},[_vm._v(" "+_vm._s(page.name)+" ")])],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }